@import '../../styles/customMediaQueries.css';

.socialLoginButton {
  display: block;
  width: 100%;
  min-height: 48px;
  margin: 0;

  padding: 0;

  transition: all var(--transitionStyleButton);
  cursor: pointer;

  font-family: var(--fontFamily);
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 24px;

  text-align: center;
  text-decoration: none;

  border: 1px solid #d2d2d2;
  border-radius: var(--borderRadiusMedium);

  background-color: var(--colorWhite);
  color: var(--colorBlack);

  /* We need to add this to position the icon inside button */
  position: relative;

  &:hover,
  &:focus {
    outline: none;
    box-shadow: var(--boxShadowButton);
    background-color: var(--colorWhite);
    text-decoration: none;
  }
  &:disabled {
    box-shadow: none;
    cursor: not-allowed;
    background-color: var(--colorGrey100);
    color: var(--colorWhite);
  }

  @media (--viewportMedium) {
    font-size: 16px;
    line-height: 24px;
    padding: 0;
  }
}

.propertyRadarLogo {
  width: 24px;
  height: auto;
  margin-right: 12px;
}