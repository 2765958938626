@import '../../../styles/customMediaQueries.css';

.root {
}

.error {
  color: var(--colorFail);
  margin: 24px 0;
}

.dropdownWrapper {
  display: flex;
  flex-direction: column;
}

.dropdownItem {
  margin: 8px 0;
}
.itemHeader {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 4px;
}
